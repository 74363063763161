<template>
    <div class="subTransfer">
        <!-- 分拨资金 资金钱包->授权失败的交易钱包 -->
        <el-dialog :title="$t('subManage.transferAndAuthorize')" :visible.sync="showTransfer" :show-close="false" center :before-close="beforeCloseTransfer">
            <div class="transfer_form">
                <div>
                    <div class="item">
                        <div class="title">{{$t('common.subAddress')}}</div>
                        <div class="inp_box">
                            <div class="g_btn btn" @click="showAddressBox=true">
                                {{$t('common.viewBtn')}}
                                <i class="iconfont icon-tips"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('subManage.chain')}}</div>
                        <div class="inp_box">
                            {{chain}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.gasCurrency')}}</div>
                        <div class="inp_box">
                            {{token}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.num')}}</div>
                        <div class="inp_box">
                            <el-input v-model="num" @input.native="inputHandler" :placeholder="$t('common.value')" class="inp" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.token')}}</div>
                        <div class="inp_box">
                            {{approve_token}}
                        </div>
                    </div>
                </div>
                <div class="btn_box">
                    <div class="btn" @click="showTransfer=false">{{$t('common.cancel')}}</div>
                    <div class="btn submit" @click="submitTransfer">{{$t('common.allotAuthorize')}}</div>
                </div>
            </div>
        </el-dialog>
        
		<!-- 交易钱包地址列表 -->
		<el-dialog class="w900" :title="$t('common.subAddress')" center :visible.sync="showAddressBox" :show-close="false">
			<div class="tips_form">
                <div v-for="(item,index) in address_list" :key="index">{{(index+1)+'. '+item}}</div>
                <div class="btn_box">
                    <div class="btn" @click="showAddressBox=false">{{$t('common.back')}}</div>
                </div>
            </div>
		</el-dialog>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import {mapGetters} from 'vuex'
// api
import { dexCollectTokenAppoint } from '@/api/markets'
export default {
    data(){
        return{
            showTransfer:false,

            num:'',

            showAddressBox:false,
        }
    },
    props:[
        'address_list','chain','token','approve_token','id_list',
        'web','exchange',
    ],
    computed:{
        ...mapGetters(['account']),
    },
    methods:{
        // 正数
        inputHandler(event) {
            let value = event.target.value.replace(/[^0-9.]/g, '');
            // 避免中文输入法影响
            setTimeout(() => {
                this.num=value >= 0 ? value : ''
            });
        },
        beforeCloseTransfer(done){
            // 初始化
            this.transfer_form={};
            done();
        },
        // 分拨资金-提交
        submitTransfer(){
            if(!this.num){
                this.$message({
                    message: this.$t('tip.dataErr'),
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else{ 
                // 提交
                const loading = Loading.service({
                    target: '.main',
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'apploading topIndex',
                });
                dexCollectTokenAppoint({
                    web:this.web,
                    chain:this.chain,
                    account:this.account,
                    num:Number(this.num),
                    token:this.token,
                    wallet_list:this.id_list,
                    currency:'',
                    approve_token:this.approve_token
                }).then(res=>{
                    // 是否去查看日志
                    this.$confirm(this.$t('tip.toSee'), this.$t('tip.tips'), {
                        confirmButtonText: this.$t('common.toSeeBtn'),
                        center: true,
                        showClose: false,
                        customClass: 'deleteOk',
                        cancelButtonText: this.$t('common.back'),
                    }).then(res=>{
                        this.$router.push({
                            path:'/robotlog',
                            query:{
                                web:this.web,
                                exchange:this.exchange,
                            }
                        })
                    })
                    // 初始化
                    this.showTransfer=false;
                    loading.close()
                }).catch(err=>{
                    loading.close()
                })
            }
        },
        open(){
            this.num='';
            this.showTransfer=true;
        }
    },
}
</script>
<style lang="stylus" scoped>
	@import 'subTransfer.styl';
</style>