// 科学计数法格式化-去除科学计数法
const scientificNotationFormat = function(num) {
    num=Number(num)
    let numStr=num.toString()
    
    let result=numStr
    let eNum=numStr.split('e-')[1]
    if(eNum){
        let dianVal=numStr.split('e-')[0].split('.')[1]
        let dianLength=dianVal?dianVal.length:0
        result=num.toFixed(Number(eNum)+dianLength)
    }
    return result
}
export default scientificNotationFormat