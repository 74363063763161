// 机器人type sub_type 格式化
const typeFormat = function(robotType,subTypeSelect){
	var type=robotType
	var sub_type='';
	if(robotType=='hupan_buy'){
		// 买盘护盘
		type='hupan';
		sub_type='buy';
	}else if(robotType=='hupan_sell'){
		// 卖盘护盘
		type='hupan';
		sub_type='sell';
	}else if(robotType=='wangge'){
		// 马丁策略
		sub_type='10000';
	}
	else if(robotType=='push'){
		// 趋势推动
		if(subTypeSelect){
			sub_type=subTypeSelect==1?'bids':'asks';
		}else{
			sub_type='bids';
		}
	}else if(robotType=='real_genpan'){
		// 跟盘机器人 standard-标准模式 rebalance-对冲模式
		if(subTypeSelect){
			sub_type=subTypeSelect==1?'standard':'rebalance';
		}else{
			sub_type='standard';
		}
	}
	return {type,sub_type}
};
export default typeFormat